body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.navbar-link:hover {
  color: black;
}

.navbar-root {
  padding: 1.5%;
  flex-basis: 5%;
}

.home-root {
  background-image: url('../assets/images/home.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.height-100-percent,
#root,
html,
body {
  height: 100%;
}

.App-root-div {
  display: flex;
  flex-direction: column;
}

.route-component-root {
  flex-basis: 95%;
}

.About-root-card {
  margin: 1%;
}

.navbar-company {
  margin-left: 25%;
}

.logo {
  width: 7%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.side-card {
  margin: 3% !important;
}

.resource-root-card {
  margin: 1% 0% 0% 1%;
}

.tool-tip {
  font-size: 14px !important;
}

.textfield-margins {
  padding: 1% 2% !important;
}

.enquiry-card-root {
  margin: 1% 5%;
}

.enquiry-card-content-root {
  padding: 0.5%;
}

.contact-card-content-root {
  padding: 1%;
}

.map-iframe {
  border: none;
}

.justify-content {
  text-align: justify;
  font-size: 13px;
}

.snackbar-content {
  background-color: #3f51b5 !important;
}

.full-height {
  height: 100%;
}

.career-root-grid {
  height: 70%;
}

.full-width {
  width: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

.justify-center {
  justify-content: center;
}

.card-media {
  height: 0;
  padding-top: 56.25%;
}

/* Mobile and Tablet media query */
@media only screen and (max-width: 900px) {
  .navbar-desktop {
    display: none !important;
  }
}

/* Laptop and Desktop media query */
@media only screen and (min-width: 900px) {
  .navbar-mobile {
    display: none !important;
  }
}

.footer {
  bottom: 0px;
  text-align: center;
  padding: 1%;
}